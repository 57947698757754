export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const USER_PROFILE = '/userprofile';
export const USER_PROFILE_DETAILS = '/userprofile/:id';
export const SEARCH = '/search';
export const REPLIES = '/replies';
export const REPLIES_DETAILS = '/replies/:uid';
export const TOS = '/termsofservice';
